import { HTMLChakraProps, chakra, Text, Box } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

export const Logo = (props: HTMLChakraProps<'img'>) => (
  <Box display="flex" alignItems="center" gap={3}>
    <Image
      src="/favicon_io/android-chrome-512x512.png"
      alt="Byron Bay Token Logo"
      height="40px"
      width="auto"
      {...props}
    />
    <Text
      fontSize="2xl"
      fontWeight="bold"
      // color="green.600"
      // fontFamily="'Playfair Display', serif"
      letterSpacing="wide"
      {...props}
    >
      Byron Bay Token
    </Text>
  </Box>
);

export default Logo;
