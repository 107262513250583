import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Badge,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner,
  Image,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../hooks/useAuth';
import { useToast } from '@chakra-ui/react';
import { gql, useMutation, useQuery } from '@apollo/client';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';

interface Client {
  id: string;
  __typename?: string;
}

const CREATE_CLIENT = gql`
  mutation CreateClient($input: ClientInput!) {
    createClient(input: $input) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

const GET_CLIENT_BY_EMAIL = gql`
  query GetClientByEmail($email: String!) {
    clientByEmail(email: $email) {
      id
      fName
      lName
      phoneNumber
    }
  }
`;

const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

const phoneInputStyle = {
  '.PhoneInputCountryIcon': {
    width: '1.5em',
    height: '1.5em'
  },
  '.PhoneInputCountrySelectArrow': {
    width: '0.5em',
    height: '0.5em'
  },
  '.PhoneInputInput': {
    height: '2.5rem',
    paddingLeft: '1rem'
  }
};

const OnboardingPage = () => {
  const { email } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [showRegistration, setShowRegistration] = useState(false);

  const [formData, setFormData] = useState({
    fName: '',
    lName: '',
    email: email || '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (email) {
      setFormData(prev => ({
        ...prev,
        email: email
      }));
    }
  }, [email]);

  const { data: membersData, loading: membersLoading, error: membersError } = useQuery(GET_MEMBERS_COUNT, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    onCompleted: (data) => {
      console.log('Members Query Completed:', {
        rawData: data,
        clientsArray: data?.clients,
        length: data?.clients?.length
      });
    },
    onError: (error) => {
      console.error('Members Query Error:', error);
    }
  });

  useEffect(() => {
    console.log('Members Data Updated:', {
      membersData,
      currentMembers: membersData?.clients?.length || 0
    });
  }, [membersData]);

  const TOTAL_LEGACY_MEMBERSHIPS = 100;
  const CURRENT_MEMBERS = membersData?.clients?.length || 0;
  const REMAINING_SPOTS = TOTAL_LEGACY_MEMBERSHIPS - CURRENT_MEMBERS;

  console.log('Calculated Values:', {
    TOTAL_LEGACY_MEMBERSHIPS,
    CURRENT_MEMBERS,
    REMAINING_SPOTS,
    rawMembersData: membersData
  });

  const [createClient, { loading: submitLoading }] = useMutation(CREATE_CLIENT, {
    refetchQueries: ['GetClients', 'GetClientByEmail'],
    onCompleted: (data) => {
      console.log('Create Client Completed:', data);
    },
    onError: (error) => {
      console.error('Create Client Error:', error);
    }
  });

  useEffect(() => {
    console.log('Onboarding Page Mounted');
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const cleanPhoneNumber = formData.phoneNumber.replace(/\s+/g, '');
      
      const { data } = await createClient({
        variables: {
          input: {
            fName: formData.fName.trim(),
            lName: formData.lName.trim(),
            email: formData.email.trim(),
            phoneNumber: cleanPhoneNumber
          }
        }
      });
      
      if (data?.createClient) {
        toast({
          title: "Success!",
          description: "You're now part of our first 100 members!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        
        setShowRegistration(false);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to submit form",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add query to check if user is already a member
  const { data: clientData, loading: clientLoading } = useQuery(GET_CLIENT_BY_EMAIL, {
    variables: { email: email || '' },
    skip: !email
  });

  const isExistingMember = Boolean(clientData?.clientByEmail);
  const memberNumber = isExistingMember && membersData?.clients 
    ? membersData.clients.findIndex((client: Client) => client.id === clientData?.clientByEmail?.id) + 1
    : null;

  return (
    <>
      <NavbarWithCallToAction />
      <Box minH="100vh" bg="#EFEDDC">
        <Container maxW="container.xl" py={20}>
          <VStack spacing={12} align="stretch">
            {/* Membership Status Badge - Mac-inspired design */}
            {isExistingMember && (
              <Box 
                bg="rgba(255, 255, 255, 0.95)"
                p={10}
                borderRadius="2xl"
                boxShadow="0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
                backdropFilter="blur(10px)"
                border="1px solid rgba(255, 255, 255, 0.18)"
              >
                <VStack spacing={6} align="stretch">
                  <Badge 
                    colorScheme="green" 
                    alignSelf="flex-start" 
                    fontSize="lg" 
                    p={3}
                    borderRadius="xl"
                    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
                  >
                    FOUNDING MEMBER #{memberNumber}
                  </Badge>
                  <Heading 
                    size="xl"
                    bgGradient="linear(to-r, #1a365d, #2a4365)"
                    bgClip="text"
                  >
                    Welcome to Byron Bay Token
                  </Heading>
                  <Text fontSize="xl" color="#2D3748" lineHeight="tall">
                    As one of our founding members, you're part of an exclusive group 
                    shaping the future of land stewardship in Byron Bay.
                  </Text>
                </VStack>
              </Box>
            )}

            {/* Early Access Status - Enhanced design */}
            <Box 
              bg="rgba(255, 255, 255, 0.95)"
              p={10}
              borderRadius="2xl"
              boxShadow="0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
              backdropFilter="blur(10px)"
              border="1px solid rgba(255, 255, 255, 0.18)"
            >
              <VStack spacing={8}>
                <Heading 
                  size="lg"
                  bgGradient="linear(to-r, #1a365d, #2a4365)"
                  bgClip="text"
                >
                  Early Access Opportunity
                </Heading>
                <Text 
                  fontSize="xl" 
                  textAlign="center" 
                  maxW="800px"
                  color="#2D3748"
                >
                  {isExistingMember 
                    ? "You're already part of our exclusive founding members group!"
                    : `Be one of only ${REMAINING_SPOTS} early members to learn about exclusive 
                       land stewardship opportunities in Byron Bay.`
                  }
                </Text>
                
                <Box 
                  w="full" 
                  maxW="600px"
                  bg="white"
                  p={8}
                  borderRadius="xl"
                  boxShadow="inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)"
                >
                  <Stat textAlign="center" mb={6}>
                    <StatLabel fontSize="lg" color="#4A5568">Available Early Access Positions</StatLabel>
                    <StatNumber 
                      fontSize="5xl"
                      bgGradient="linear(to-r, #1a365d, #2a4365)"
                      bgClip="text"
                    >
                      {membersLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        `${REMAINING_SPOTS} of ${TOTAL_LEGACY_MEMBERSHIPS}`
                      )}
                    </StatNumber>
                  </Stat>

                  <Progress 
                    value={CURRENT_MEMBERS} 
                    max={TOTAL_LEGACY_MEMBERSHIPS} 
                    size="lg" 
                    colorScheme="blue" 
                    w="full"
                    mb={6}
                    hasStripe
                    isAnimated
                    borderRadius="full"
                    boxShadow="inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)"
                  />
                  
                  <Text fontSize="lg" textAlign="center" color="#4A5568">
                    {CURRENT_MEMBERS} member{CURRENT_MEMBERS !== 1 ? 's' : ''} {CURRENT_MEMBERS !== 1 ? 'have' : 'has'} already secured their position
                  </Text>
                </Box>
              </VStack>
            </Box>

            {/* Benefits Section */}
            <Box bg="white" p={8} borderRadius="xl">
              <VStack spacing={8} align="stretch">
                <Heading size="lg">Early Access Benefits</Heading>

                <Box
                  position="relative"
                  width="100%"
                  minHeight="300px"
                  height="auto"
                  overflow="hidden"
                  borderRadius="xl"
                >
                  <Image
                    src="/land/land_preview_birds.png"
                    alt="Aerial view of the land"
                    objectFit="contain"
                    w="100%"
                    h="auto"
                    fallback={<Spinner />}
                  />
                </Box>

                <Box>
                  <Heading size="md" mb={4}>Priority Property Access</Heading>
                  <Text fontSize="lg">
                    First access to exclusive land stewardship opportunities in Byron Bay
                  </Text>
                </Box>
                
                <Box>
                  <Heading size="md" mb={4}>The Land</Heading>
                  <Text fontSize="lg">
                    The land is situated in the heart of Byron Bay and is currently zoned as XXX. 
                    While you can't build on it right now, the real value lies in becoming a steward 
                    of the land - preserving it, admiring it, and sharing it with others, showcasing 
                    how you are contributing to the preservation of this beautiful piece of land.
                  </Text>
                </Box>

                <Box>
                  <Text fontSize="md" color="gray.600" fontStyle="italic">
                    Stay tuned for more details about this exclusive opportunity. 
                    We'll be sharing more information about land rights and stewardship 
                    opportunities in the coming months.
                  </Text>
                </Box>
              </VStack>
            </Box>

            {/* Registration Section - Enhanced design */}
            {!isExistingMember ? (
              showRegistration ? (
                <Box 
                  bg="rgba(255, 255, 255, 0.95)"
                  p={10}
                  borderRadius="2xl"
                  boxShadow="0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
                  backdropFilter="blur(10px)"
                  border="1px solid rgba(255, 255, 255, 0.18)"
                >
                  <VStack spacing={6}>
                    <Heading size="md">Complete Your Registration</Heading>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                      <VStack spacing={4}>
                        <FormControl isRequired>
                          <FormLabel>First Name</FormLabel>
                          <Input
                            name="fName"
                            value={formData.fName}
                            onChange={handleInputChange}
                            placeholder="Enter first name"
                          />
                        </FormControl>

                        <FormControl isRequired>
                          <FormLabel>Last Name</FormLabel>
                          <Input
                            name="lName"
                            value={formData.lName}
                            onChange={handleInputChange}
                            placeholder="Enter last name"
                          />
                        </FormControl>

                        <FormControl isRequired>
                          <FormLabel>Email</FormLabel>
                          <Input
                            name="email"
                            type="email"
                            value={formData.email}
                            isReadOnly
                            bg="gray.50"
                            _readOnly={{ cursor: 'not-allowed' }}
                          />
                        </FormControl>

                        <FormControl isRequired>
                          <FormLabel>Phone Number</FormLabel>
                          <PhoneInput
                            international
                            defaultCountry="AU"
                            value={formData.phoneNumber}
                            onChange={(value) => {
                              setFormData(prev => ({ ...prev, phoneNumber: value || '' }))
                            }}
                            placeholder="Enter phone number"
                            style={phoneInputStyle}
                          />
                        </FormControl>

                        <Button
                          type="submit"
                          bg="#8b0100"
                          color="white"
                          _hover={{ bg: '#a30200' }}
                          size="lg"
                          width="full"
                          mt={4}
                          isLoading={submitLoading}
                        >
                          Complete Registration
                        </Button>
                      </VStack>
                    </form>
                  </VStack>
                </Box>
              ) : (
                <Box textAlign="center">
                  <Button
                    bg="#1a365d"
                    color="white"
                    _hover={{ bg: '#2a4365' }}
                    size="lg"
                    onClick={() => setShowRegistration(true)}
                    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
                    borderRadius="xl"
                    px={8}
                    py={6}
                    fontSize="lg"
                  >
                    Register Now
                  </Button>
                </Box>
              )
            ) : (
              <Box 
                textAlign="center" 
                bg="rgba(255, 255, 255, 0.95)"
                p={8}
                borderRadius="2xl"
                boxShadow="0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
                backdropFilter="blur(10px)"
                border="1px solid rgba(255, 255, 255, 0.18)"
              >
                <Badge 
                  colorScheme="green" 
                  fontSize="lg" 
                  p={3} 
                  borderRadius="xl"
                  boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
                  mb={4}
                >
                  REGISTRATION COMPLETE
                </Badge>
                <Text 
                  fontSize="xl" 
                  fontWeight="medium"
                  bgGradient="linear(to-r, #1a365d, #2a4365)"
                  bgClip="text"
                  mb={6}
                >
                  You're already registered as member #{memberNumber}
                </Text>
                <Button
                  onClick={() => navigate('/preview-offer')}
                  bg="#1a365d"
                  color="white"
                  _hover={{ bg: '#2a4365' }}
                  size="lg"
                  boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1)"
                >
                  View Preview Draft Offer
                </Button>
              </Box>
            )}
          </VStack>
        </Container>
      </Box>
      <FooterWithFourColumns />
    </>
  );
};

export default OnboardingPage; 