import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  Badge,
  Grid,
  GridItem,
  List,
  ListItem,
  Link,
  Image,
  useColorModeValue,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useNavigate } from 'react-router-dom';

interface RightConfig {
  text: string;
  buttonText: string;
  action: () => void;
}

// Add this SVG seal as a constant
const CERTIFICATE_SEAL = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI0NSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJnb2xkIiBzdHJva2Utd2lkdGg9IjIiLz48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJnb2xkIiBzdHJva2Utd2lkdGg9IjEiLz48dGV4dCB4PSI1MCIgeT0iNDUiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGZvbnQtZmFtaWx5PSJzZXJpZiIgZmlsbD0iZ29sZCIgZm9udC1zaXplPSIxMCI+QllST048L3RleHQ+PHRleHQgeD0iNTAiIHk9IjU1IiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBmb250LWZhbWlseT0ic2VyaWYiIGZpbGw9ImdvbGQiIGZvbnQtc2l6ZT0iMTAiPkJBWTwvdGV4dD48cGF0aCBkPSJNNTAgMTBBNDAgNDAgMCAwIDEgOTAgNTBBNDAgNDAgMCAwIDEgNTAgOTBBNDAgNDAgMCAwIDEgMTAgNTBBNDAgNDAgMCAwIDEgNTAgMTBaIiBmaWxsPSJub25lIiBzdHJva2U9ImdvbGQiIHN0cm9rZS13aWR0aD0iMSIvPjxwYXRoIGQ9Ik0yMCAzMEw4MCA3ME04MCAzMEwyMCA3MCIgc3Ryb2tlPSJnb2xkIiBzdHJva2Utd2lkdGg9IjEiIG9wYWNpdHk9IjAuNSIvPjwvc3ZnPg==`;

const ExerciseRights = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [modalContent, setModalContent] = React.useState({
    title: '',
    content: '',
  });

  const openModal = (title: string, content: string) => {
    setModalContent({ title, content });
    onOpen();
  };

  const stewardshipRights: RightConfig[] = [
    {
      text: "Right to access the area for recreational purposes, such as hiking, picnicking, and wildlife observation.",
      buttonText: "Book Walk",
      action: () => openModal(
        "Book a Walk",
        "Schedule your recreational visit to the land. Our booking system will allow you to reserve specific dates for hiking, picnicking, and wildlife observation. Coming soon!"
      )
    },
    {
      text: "Right to camp at the designated camping area for up to 1 week per year for up to 5 people, and you will have the right to put this in the booking calendar once the campaign has finished, you will need your NFT's to book, subject to local regulations and guidelines.",
      buttonText: "Book Camping",
      action: () => openModal(
        "Book Camping",
        "Reserve your annual camping spot. You can book up to 1 week for 5 people. NFT verification will be required for booking. System launching after campaign completion."
      )
    },
    {
      text: "Right to participate in conservation and management decisions through a co-management structure.",
      buttonText: "Vote Now",
      action: () => openModal(
        "Conservation Voting",
        "Participate in key decisions about land conservation and management. Current voting topics will be displayed here once the co-management structure is established."
      )
    },
    {
      text: "Right to transfer or sell the stewardship rights to another party, subject to the terms and conditions of the original agreement.",
      buttonText: "Transfer Rights",
      action: () => openModal(
        "Transfer Rights",
        "Initiate a transfer of your stewardship rights. This process will require verification and agreement to terms. Transfer marketplace coming soon."
      )
    },
    {
      text: "Right to be informed about any changes in zoning or legal status.",
      buttonText: "View Updates",
      action: () => openModal(
        "Zoning & Legal Updates",
        "Access the latest information about zoning changes and legal status updates. No current updates to display."
      )
    },
    {
      text: "Right to join and participate in a community of like-minded stewards.",
      buttonText: "Join Community",
      action: () => openModal(
        "Steward Community",
        "Connect with fellow land stewards. Community platform features will include forums, events, and collaborative projects. Launch date coming soon."
      )
    },
    {
      text: "Right to contribute to nature's protection and preservation for future generations.",
      buttonText: "Contribute",
      action: () => openModal(
        "Conservation Initiatives",
        "Support ongoing conservation efforts. View current projects and contribution opportunities. Initiative tracking system in development."
      )
    },
    {
      text: "Right to access educational resources and workshops related to conservation and management.",
      buttonText: "Access Resources",
      action: () => openModal(
        "Educational Resources",
        "Browse our collection of conservation workshops, guides, and educational materials. Resource library launching soon."
      )
    },
    {
      text: "Right to be acknowledged as a steward and receive a unique certificate of stewardship.",
      buttonText: "View Certificate",
      action: () => openModal(
        "Stewardship Certificate",
        "Your digital certificate of stewardship. NFT-backed verification system coming soon."
      )
    },
    {
      text: "Right to enjoy the natural beauty and serenity without the responsibility of day-to-day maintenance and upkeep, which will be managed by the co-management structure.",
      buttonText: "View Management",
      action: () => openModal(
        "Management Structure",
        "Review our co-management approach and maintenance plans. Regular updates will be posted here."
      )
    },
    {
      text: "Right to access all legal documentation, including zoning information, surveys, and any relevant agreements or contracts.",
      buttonText: "View Documents",
      action: () => openModal(
        "Legal Documents",
        "Access zoning information, surveys, and relevant agreements. Document repository coming soon."
      )
    },
    {
      text: "Right to raise concerns and report them to other stewards through 'Management and Legals Docs Log'.",
      buttonText: "Report",
      action: () => openModal(
        "Report Concerns",
        "Submit and track concerns through our Management and Legals Docs Log. Reporting system in development."
      )
    },
    {
      text: "Right to be informed about any proposed changes to use or management, with the opportunity to provide input and feedback on the log.",
      buttonText: "View Proposals",
      action: () => openModal(
        "Management Proposals",
        "Review and comment on proposed changes to land use and management. Notification system coming soon."
      )
    },
    {
      text: "Right to participate in online forums and discussions with other stewards to share ideas, experiences, and knowledge related to nature stewardship.",
      buttonText: "Join Forums",
      action: () => openModal(
        "Community Forums",
        "Engage with other stewards in our online discussion forums. Platform launch coming soon."
      )
    },
    {
      text: "Right to access an annual report via the log on the status, including any improvements, challenges, or upcoming initiatives.",
      buttonText: "View Report",
      action: () => openModal(
        "Annual Report",
        "Access detailed reports on land status, improvements, and initiatives. First report will be available after campaign completion."
      )
    },
    {
      text: "Right to be invited to and participate in online and/or in-person events and activities related to conservation.",
      buttonText: "View Events",
      action: () => openModal(
        "Conservation Events",
        "Browse and register for upcoming conservation events and activities. Event calendar launching soon."
      )
    },
    {
      text: "Right to propose and vote on initiatives or projects that enhance ecological value and biodiversity.",
      buttonText: "Submit Proposal",
      action: () => openModal(
        "Initiative Proposals",
        "Submit your ideas for conservation projects and improvements. Proposal system in development."
      )
    },
    {
      text: "Right to have a say in the selection and appointment of any third-party organisations or individuals involved in management or conservation.",
      buttonText: "Vote",
      action: () => openModal(
        "Management Selection",
        "Participate in voting for management appointments. Voting system will be available when needed."
      )
    },
    {
      text: "Right to be informed about any potential risks or hazards, as well as any measures taken to mitigate them.",
      buttonText: "View Risks",
      action: () => openModal(
        "Risk Assessment",
        "Access current risk assessments and mitigation measures. Updates posted as needed."
      )
    },
    {
      text: "Right to pass on the stewardship rights to heirs or beneficiaries, ensuring a long-term commitment to preservation.",
      buttonText: "Plan Transfer",
      action: () => openModal(
        "Heritage Planning",
        "Set up inheritance arrangements for your stewardship rights. Transfer system in development."
      )
    }
  ];

  return (
    <>
      <NavbarWithCallToAction />
      <Box minH="100vh" bg="#f5f5f7">
        <Container maxW="container.xl" py={10}>
          <VStack spacing={6} align="stretch">
            {/* Header Section - More compact */}
            <Card bg={bgColor} boxShadow="xl" borderRadius="2xl">
              <CardBody p={6}>
                <VStack spacing={2} align="center">
                  <Badge colorScheme="green" fontSize="md">
                    YOUR ACTIVE RIGHTS
                  </Badge>
                  <Heading size="lg" textAlign="center">
                    Byron Bay Land Stewardship Rights
                  </Heading>
                </VStack>
              </CardBody>
            </Card>

            {/* Certificate Section - More elegant and compact */}
            <Card
              bg={bgColor}
              boxShadow="xl"
              borderRadius="2xl"
              border="2px solid"
              borderColor="gold"
            >
              <CardBody p={6}>
                <Grid
                  templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                  gap={6}
                  alignItems="center"
                >
                  <GridItem>
                    <VStack spacing={4} align="center">
                      <Image
                        src={CERTIFICATE_SEAL}
                        alt="Certificate Seal"
                        boxSize="100px"
                        opacity={0.9}
                        filter="drop-shadow(0 0 2px rgba(218, 165, 32, 0.5))"
                      />
                      <Heading size="md" textAlign="center" fontFamily="serif">
                        Certificate of Land Stewardship
                      </Heading>
                      <Text fontSize="md" textAlign="center">
                        This certifies that the holder owns stewardship rights to
                      </Text>
                    </VStack>
                  </GridItem>

                  <GridItem>
                    <VStack spacing={4}>
                      <Box
                        width="100%"
                        maxW="300px"
                        borderRadius="xl"
                        overflow="hidden"
                        border="1px solid"
                        borderColor={borderColor}
                      >
                        <Image
                          src="/land/square_meter.png"
                          alt="Square meter visualization"
                          objectFit="cover"
                          w="100%"
                          h="auto"
                        />
                      </Box>
                      <VStack spacing={2} fontSize="sm" fontFamily="mono">
                        <Text fontWeight="bold">Property Details:</Text>
                        <Text>Square meter 1 NFTID: 0X</Text>
                        <Text color="gray.600">lat = -28.648671718117782</Text>
                        <Text color="gray.600">lon = 153.59102478979372</Text>
                        <Text mt={1}>Square meter 2 NFTID: 0X</Text>
                        <Text color="gray.600">lat = -28.648742538294561</Text>
                        <Text color="gray.600">lon = 153.59112739184022</Text>
                      </VStack>
                    </VStack>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>

            {/* Rights Section - More organized */}
            <Card bg={bgColor} boxShadow="xl" borderRadius="2xl">
              <CardBody p={6}>
                <Heading size="md" mb={4}>Your Active Stewardship Rights:</Heading>
                <List spacing={3}>
                  {stewardshipRights.map((right, index) => (
                    <ListItem
                      key={index}
                      p={4}
                      bg="gray.50"
                      borderRadius="lg"
                      border="1px solid"
                      borderColor={borderColor}
                    >
                      <Grid
                        templateColumns={{ base: "1fr", md: "1fr auto" }}
                        gap={4}
                        alignItems="center"
                      >
                        <Box>
                          <Text fontSize="sm" mb={2}>
                            {index + 1}. {right.text}
                          </Text>
                          <Link color="green.600" fontSize="xs">
                            View Management and Legals Docs Log
                          </Link>
                        </Box>
                        <Button
                          colorScheme="green"
                          size="sm"
                          onClick={right.action}
                          minW="120px"
                          _hover={{ bg: 'green.600' }}
                        >
                          {right.buttonText}
                        </Button>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </CardBody>
            </Card>
          </VStack>
        </Container>
      </Box>
      <FooterWithFourColumns />
      
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay 
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
        />
        <ModalContent>
          <ModalHeader 
            borderBottom="1px" 
            borderColor={borderColor}
            bg={useColorModeValue('gray.50', 'gray.900')}
          >
            {modalContent.title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={6}>
            <Text>{modalContent.content}</Text>
          </ModalBody>
          <ModalFooter borderTop="1px" borderColor={borderColor}>
            <Button colorScheme="green" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExerciseRights; 