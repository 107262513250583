import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Center
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Logo } from '../../Logo'
// import { links } from './_data'
import InviteLink from '../../../components/InviteLink';
import { useAuth } from '../../../contexts/AuthContext';
import { CartPreview } from '../../CartPreview';






export const FooterWithFourColumns = () => {
  const { user } = useAuth();


  const links = [
    {
      links: [
        ...(user ? [{ label: 'Membership Access', href: '/100members' }] : []),
        // ... commenting out other links for now
      ],
    },
    {
      links: [
        // Commenting out existing links for now
      ],
    },
    {
      title: 'Resources',
      links: [
        // Commenting out blog and other links for now
      ],
    },
    {
      title: 'Admin',
      links: [
        // Commenting out admin links for now
      ],
    },
  ]



  return (<>

    <Box bg="#d7cbb4" m={0}>
      {/* <InviteLink /> */}
      <Container as="footer" role="contentinfo" maxW="6xl" >
        <Stack
          justify="space-between"
          align="start"
          direction={{ base: 'column', lg: 'row' }}
          py={{ base: '12', md: '16' }}
          spacing="4"
        >

          <Stack spacing={{ base: '3', md: '8' }} align="start">

            <Logo />
           
          </Stack>
          <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={{ base: 'full', lg: 'auto' }}>
            {links.map((group, idx) => (
              <Stack key={idx} spacing="3" minW={{ lg: '40' }}>

                <Stack spacing="3" shouldWrapChildren>

                  {group.links.map((link, idx, title) => (<>

                    <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href} size="xs">
                      {link.label}
                    </Button>

                  </>

                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Divider />
        <Stack
          pt="8"
          pb="12"
          justify="space-between"
          direction={{ base: 'column-reverse', md: 'row' }}
          align="center"
        >

          <Box w="full">
            <Center w="full">
              <Stack spacing={2} textAlign="center" w="full">
                <Text fontSize="sm" color="fg.subtle">
                  &copy; {new Date().getFullYear()} Byron Bay Token
                </Text>
                <Text fontSize="sm" color="gray.600">
                  Premium Property Access • Land Stewardship Opportunities
                </Text>
                <Text fontSize="xs" color="gray.500">
                  Based in Byron Bay, Australia
                </Text>
                <Badge colorScheme="purple" mx="auto">Concept Website</Badge>
                <Text fontSize="xs" color="gray.400" fontStyle="italic">
                  You will be notified when this goes to production
                </Text>
              </Stack>
            </Center>
          </Box>
          {/* <ButtonGroup variant="tertiary">
            <IconButton as="a" href="https://x.com/ThriveResilient" target="_blank" aria-label="Twitter" icon={<FaTwitter />} />
          </ButtonGroup> */}
        </Stack>
      </Container>
    </Box>

    <Box
      position="fixed"
      bottom={4}
      right={4}
      zIndex={999}
    >
      <CartPreview />
    </Box>
  </>
  )
}