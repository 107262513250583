import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  Button,
  Text,
  Image,
  Badge,
  useColorModeValue,
  Divider,
  Card,
  CardBody,
  Icon,
  HStack,
} from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { FaLock, FaCertificate, FaCheckCircle } from 'react-icons/fa';
import L from 'leaflet';

const GET_CLIENT_BY_EMAIL = gql`
  query GetClientByEmail($email: String!) {
    clientByEmail(email: $email) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

interface MapLocation {
  id: string;
  lat: number;
  lon: number;
}

const OrderPage = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { email } = useAuth();
  const navigate = useNavigate();
  
  const { loading, error, data } = useQuery(GET_CLIENT_BY_EMAIL, {
    variables: { email },
    skip: !email
  });

  const client = data?.clientByEmail;

  const mapRef = useRef<L.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(null);

  // Example token locations - in production, these would come from your backend/smart contract
  const tokenLocations: MapLocation[] = [
    {
      id: "1",
      lat: -28.648671718117782,
      lon: 153.59102478979372
    },
    {
      id: "2",
      lat: -28.648742538294561,
      lon: 153.59112739184022
    }
  ];

  useEffect(() => {
    // If map is already initialized or container doesn't exist, return early
    if (mapRef.current || !mapContainerRef.current) return;

    // Initialize map
    mapRef.current = L.map(mapContainerRef.current).setView(
      [-28.648671718117782, 153.59102478979372],
      19
    );

    // Add tile layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 24
    }).addTo(mapRef.current);

    // Add markers for each token location
    tokenLocations.forEach((location) => {
      const offset = 0.000005; // Approximate offset for 1 meter
      
      // Create square polygon
      const square = L.polygon([
        [location.lat + offset, location.lon - offset],
        [location.lat + offset, location.lon + offset],
        [location.lat - offset, location.lon + offset],
        [location.lat - offset, location.lon - offset]
      ], {
        color: 'blue',
        weight: 2,
        fillOpacity: 0.3
      }).addTo(mapRef.current!);

      // Add click handler
      square.on('click', () => {
        setSelectedLocation(location);
      });

      // Add hover tooltip
      square.bindTooltip(`Token ID: ${location.id}`);
    });

    // Cleanup function
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []); // Empty dependency array

  const handlePayment = () => {
    navigate('/exercise-rights');
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Box minH="100vh" bg="#f5f5f7">
        <Container maxW="container.xl" py={20}>
          <VStack spacing={8}>
            <Card
              bg={bgColor}
              borderRadius="2xl"
              boxShadow="xl"
              w="full"
              maxW="900px"
              border="1px solid"
              borderColor={borderColor}
              overflow="hidden"
            >
              <CardBody p={0}>
                <Box 
                  bg="gray.50" 
                  p={6} 
                  borderBottom="1px solid"
                  borderColor={borderColor}
                >
                  <HStack spacing={3} justify="center">
                    <Icon as={FaCertificate} w={6} h={6} color="orange.500" />
                    <Heading size="lg">Digital Land Certificate</Heading>
                  </HStack>
                </Box>

                <Box p={8}>
                  <VStack spacing={8}>
                    <Box
                      display={{ base: 'block', md: 'grid' }}
                      gridTemplateColumns={{ md: '1fr auto' }}
                      gap={8}
                      w="full"
                    >
                      <Box
                        bg="gray.50"
                        p={6}
                        borderRadius="xl"
                        border="1px solid"
                        borderColor={borderColor}
                      >
                        <VStack align="stretch" spacing={4}>
                          <HStack>
                            <Icon as={FaCheckCircle} color="green.500" />
                            <Heading size="md">Verified Owner Details</Heading>
                          </HStack>
                          <Text fontSize="lg"><strong>Name:</strong> {client?.fName} {client?.lName}</Text>
                          <Text fontSize="lg"><strong>Email:</strong> {client?.email}</Text>
                          <Text fontSize="lg"><strong>Phone:</strong> {client?.phoneNumber}</Text>
                          
                          <Divider />
                          
                          <Heading size="md">Property Details</Heading>
                          <Text fontSize="lg" fontWeight="medium">2 Square Meters - Byron Bay Premium Location</Text>
                          <Box fontSize="md" bg="white" p={4} borderRadius="md">
                            <Text><strong>Square meter 1 NFTID:</strong> 0X</Text>
                            <Text color="gray.600">lat = -28.648671718117782</Text>
                            <Text color="gray.600">lon = 153.59102478979372</Text>
                            
                            <Text mt={3}><strong>Square meter 2 NFTID:</strong> 0X</Text>
                            <Text color="gray.600">lat = -28.648742538294561</Text>
                            <Text color="gray.600">lon = 153.59112739184022</Text>
                          </Box>

                          <Heading size="md">Property Location</Heading>
                          <Box 
                            ref={mapContainerRef}
                            id="map" 
                            h="300px" 
                            w="100%" 
                            borderRadius="md"
                            overflow="hidden"
                          />
                          
                          {selectedLocation && (
                            <Box mt={4}>
                              <Text fontWeight="bold">Selected Square Meter:</Text>
                              <Text>Token ID: {selectedLocation.id}</Text>
                              <Text>Latitude: {selectedLocation.lat}</Text>
                              <Text>Longitude: {selectedLocation.lon}</Text>
                            </Box>
                          )}
                        </VStack>
                      </Box>

                      <Box
                        position="relative"
                        width={{ base: "100%", md: "200px" }}
                        height="200px"
                        overflow="hidden"
                        borderRadius="xl"
                        border="1px solid"
                        borderColor={borderColor}
                      >
                        <Image
                          src="/land/square_meter.png"
                          alt="Square meter visualization"
                          objectFit="cover"
                          w="100%"
                          h="100%"
                          fallback={<Box bg="gray.100" h="200px" />}
                        />
                      </Box>
                    </Box>

                    <Box 
                      w="full" 
                      bg="gray.50" 
                      p={6} 
                      borderRadius="xl"
                      border="1px solid"
                      borderColor={borderColor}
                    >
                      <VStack spacing={4}>
                        <Heading size="md">Secure Payment Options</Heading>
                        <Button
                          size="lg"
                          w="full"
                          colorScheme="orange"
                          h="60px"
                          onClick={handlePayment}
                          _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                          transition="all 0.2s"
                        >
                          <HStack spacing={2}>
                            <Icon as={FaLock} />
                            <VStack spacing={0}>
                              <Text>Pay with Bitcoin</Text>
                              <Badge colorScheme="green">5% Discount!</Badge>
                            </VStack>
                          </HStack>
                        </Button>

                        <Button
                          size="lg"
                          w="full"
                          colorScheme="teal"
                          h="60px"
                          onClick={handlePayment}
                          _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                          transition="all 0.2s"
                        >
                          <HStack spacing={2}>
                            <Icon as={FaLock} />
                            <VStack spacing={0}>
                              <Text>Pay with USDT</Text>
                              <Badge colorScheme="green">50% Discount!</Badge>
                            </VStack>
                          </HStack>
                        </Button>

                        <Text fontSize="sm" color="gray.500" textAlign="center">
                          <Icon as={FaLock} display="inline" mr={1} />
                          Secure, encrypted payment processing
                        </Text>
                      </VStack>
                    </Box>
                  </VStack>
                </Box>
              </CardBody>
            </Card>
          </VStack>
        </Container>
      </Box>
      <FooterWithFourColumns />
    </>
  );
};

export default OrderPage; 