import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  Badge,
  Grid,
  GridItem,
  List,
  ListItem,
  Link,
  Divider,
  Image,
  HStack,
  useColorModeValue,
  Spinner,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Code,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import { ExternalLinkIcon } from '@chakra-ui/icons';

interface MapLocation {
  id: string;
  lat: number;
  lon: number;
}

const PreviewOffer = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const navigate = useNavigate();
  const mapRef = useRef<L.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [numberOfSquareMeters, setNumberOfSquareMeters] = useState<number>(2);
  const [squareMeters, setSquareMeters] = useState<MapLocation[]>([]);
  
  // Base coordinates
  const baseCoordinates = {
    lat: -28.648671718117782,
    lon: 153.59102478979372
  };

  // Generate square meters based on number input
  const generateSquareMeters = (count: number) => {
    const meters: MapLocation[] = [];
    const meterOffset = 0.000009; // Approximate 1-meter offset in degrees

    for (let i = 0; i < count; i++) {
      meters.push({
        id: (i + 1).toString(),
        lat: baseCoordinates.lat,
        lon: baseCoordinates.lon + (meterOffset * i) // Move right by adding to longitude
      });
    }
    return meters;
  };

  // Update squares when number changes
  useEffect(() => {
    const newSquares = generateSquareMeters(numberOfSquareMeters);
    setSquareMeters(newSquares);
  }, [numberOfSquareMeters]);

  // Map initialization effect
  useEffect(() => {
    if (mapRef.current || !mapContainerRef.current) return;

    mapRef.current = L.map(mapContainerRef.current).setView(
      [baseCoordinates.lat, baseCoordinates.lon],
      19
    );

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 24
    }).addTo(mapRef.current);

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  // Effect to update markers when squareMeters changes
  useEffect(() => {
    if (!mapRef.current) return;

    // Clear existing markers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.remove();
      }
    });

    // Add new markers
    squareMeters.forEach((location) => {
      const offset = 0.000005; // Approximate offset for visual square size
      
      const square = L.polygon([
        [location.lat + offset, location.lon - offset],
        [location.lat + offset, location.lon + offset],
        [location.lat - offset, location.lon + offset],
        [location.lat - offset, location.lon - offset]
      ], {
        color: 'blue',
        weight: 2,
        fillOpacity: 0.3
      }).addTo(mapRef.current!);

      square.bindTooltip(`Square Meter ${location.id}`);
    });
  }, [squareMeters]);

  const stewardshipRights = [
    "Right to access the area for recreational purposes, such as hiking, picnicking, and wildlife observation.",
    "Right to camp at the designated camping area for up to 1 week per year for up to 5 people, and you will have the right to put this in the booking calendar once the campaign has finished, you will need your NFT's to book, subject to local regulations and guidelines.",
    "Right to participate in conservation and management decisions through a co-management structure.",
    "Right to transfer or sell the stewardship rights to another party, subject to the terms and conditions of the original agreement.",
    "Right to be informed about any changes in zoning or legal status.",
    "Right to join and participate in a community of like-minded stewards.",
    "Right to contribute to nature's protection and preservation for future generations.",
    "Right to access educational resources and workshops related to conservation and management.",
    "Right to be acknowledged as a steward and receive a unique certificate of stewardship.",
    "Right to enjoy the natural beauty and serenity without the responsibility of day-to-day maintenance and upkeep, which will be managed by the co-management structure.",
    "Right to access all legal documentation, including zoning information, surveys, and any relevant agreements or contracts.",
    "Right to raise concerns and report them to other stewards through 'Management and Legals Docs Log'.",
    "Right to be informed about any proposed changes to use or management, with the opportunity to provide input and feedback on the log.",
    "Right to participate in online forums and discussions with other stewards to share ideas, experiences, and knowledge related to nature stewardship.",
    "Right to access an annual report via the log on the status, including any improvements, challenges, or upcoming initiatives.",
    "Right to be invited to and participate in online and/or in-person events and activities related to conservation.",
    "Right to propose and vote on initiatives or projects that enhance ecological value and biodiversity.",
    "Right to have a say in the selection and appointment of any third-party organisations or individuals involved in management or conservation.",
    "Right to be informed about any potential risks or hazards, as well as any measures taken to mitigate them.",
    "Right to pass on the stewardship rights to heirs or beneficiaries, ensuring a long-term commitment to preservation."
  ];

  const [agreements, setAgreements] = React.useState<boolean[]>(new Array(stewardshipRights.length).fill(false));
  
  const allAgreed = React.useMemo(() => {
    return agreements.every(agreement => agreement === true);
  }, [agreements]);

  const handleAgree = (index: number) => {
    const newAgreements = [...agreements];
    newAgreements[index] = !newAgreements[index];
    setAgreements(newAgreements);
  };

  const handleOrder = () => {
    navigate('/order');
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Box minH="100vh" bg="#EFEDDC">
        <Container maxW="container.xl" py={6}>
          {/* Compact Header with Early Member Discounts */}
          <VStack spacing={4} align="stretch" mb={6}>
            <Heading size="lg">Preview Your Land Stewardship Offer</Heading>
            <Text color="gray.600">Select your square meters and review your rights</Text>
            <HStack spacing={4} wrap="wrap" color="gray.700">
              <Badge colorScheme="blue" p={2} borderRadius="md">Next 100 Members: 25% Off</Badge>
              <Badge colorScheme="purple" p={2} borderRadius="md">Next 100 Members: 20% Off</Badge>
              <Badge colorScheme="orange" p={2} borderRadius="md">Next 100 Members: 15% Off</Badge>
              <Badge colorScheme="red" p={2} borderRadius="md">Next 100 Members: 10% Off</Badge>
            </HStack>
          </VStack>

          {/* Main Content Grid */}
          <Grid templateColumns={{ base: '1fr', lg: '2fr 1fr' }} gap={6}>
            {/* Left Column - Map and Details */}
            <GridItem>
              <Box
                bg={bgColor}
                p={6}
                borderRadius="xl"
                boxShadow="xl"
                border="1px solid"
                borderColor={borderColor}
              >
                {/* Map container */}
                <Box 
                  ref={mapContainerRef}
                  id="map" 
                  h="400px" 
                  w="100%" 
                  borderRadius="md"
                  overflow="hidden"
                  mb={4}
                />

                {/* Square meter details in a scrollable container */}
                <Box maxH="200px" overflowY="auto" p={2}>
                  <Text fontWeight="bold" mb={2}>Selected Locations:</Text>
                  <List spacing={2}>
                    {squareMeters.map((meter) => (
                      <ListItem key={meter.id}>
                        Square meter {meter.id} NFTID: 0X lat = {meter.lat.toFixed(12)} lon = {meter.lon.toFixed(12)}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </GridItem>

            {/* Right Column - Selection and Image */}
            <GridItem>
              <VStack spacing={6}>
                {/* Square Meter Selection */}
                <Box
                  bg={bgColor}
                  p={6}
                  borderRadius="xl"
                  boxShadow="xl"
                  border="1px solid"
                  borderColor={borderColor}
                  w="100%"
                >
                  <Heading size="md" mb={4}>Select Your Square Meters</Heading>
                  <FormControl>
                    <FormLabel>Number of Square Meters</FormLabel>
                    <NumberInput
                      min={1}
                      max={100}
                      value={numberOfSquareMeters}
                      onChange={(_, value) => setNumberOfSquareMeters(value)}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>

                {/* Image Box */}
                <Box
                  bg={bgColor}
                  borderRadius="xl"
                  boxShadow="xl"
                  border="1px solid"
                  borderColor={borderColor}
                  overflow="hidden"
                  w="100%"
                >
                  <Image
                    src="/land/land_preview_birds.png"
                    alt="Byron Bay Birds Eye View"
                    w="100%"
                    h="auto"
                    objectFit="cover"
                  />
                </Box>
              </VStack>
            </GridItem>
          </Grid>

          {/* Stewardship Rights Section */}
          <Box
            bg={bgColor}
            p={8}
            borderRadius="xl"
            boxShadow="xl"
            border="1px solid"
            borderColor={borderColor}
            mt={6}
          >
            <Heading size="lg" mb={6}>Stewardship Rights to Protect and Preserve Nature:</Heading>
            <List spacing={4}>
              {stewardshipRights.map((right, index) => (
                <ListItem 
                  key={index} 
                  p={4}
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box flex="1">
                    <Text mb={2}>{index + 1}. {right}</Text>
                    <Link color="blue.500" fontSize="sm">
                      View Management and Legals Docs Log
                    </Link>
                  </Box>
                  <Button
                    ml={4}
                    colorScheme={agreements[index] ? "green" : "gray"}
                    onClick={() => handleAgree(index)}
                    size="sm"
                  >
                    {agreements[index] ? "Agreed" : "I Agree"}
                  </Button>
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Call to Action */}
          <Box textAlign="center" py={6}>
            <Button
              colorScheme="blue"
              size="lg"
              px={8}
              py={6}
              fontSize="xl"
              isDisabled={!allAgreed}
              onClick={handleOrder}
            >
              {allAgreed 
                ? "Purchase Land Stewardship Rights" 
                : `Agree to All Rights (${agreements.filter(a => a).length}/${stewardshipRights.length})`
              }
            </Button>
            {!allAgreed && (
              <Text mt={2} color="gray.600">
                Please agree to all stewardship rights to proceed
              </Text>
            )}
          </Box>

          {/* New Section: Technical Details */}
          <Box
            bg={bgColor}
            p={8}
            borderRadius="xl"
            boxShadow="xl"
            border="1px solid"
            borderColor={borderColor}
            mt={6}
          >
            <Heading size="lg" mb={6}>How Our Token System Works</Heading>
            
            <VStack align="stretch" spacing={6}>
              {/* Token Explanation */}
              <Box>
                <Heading size="md" mb={4}>Land Rights & Governance System</Heading>
                <Text mb={3}>Our system uses two types of tokens to manage land stewardship rights and governance:</Text>
                <List spacing={4}>
                  <ListItem>
                    <Text fontWeight="bold">ERC1155 Land Tokens:</Text>
                    <Text>Represents your actual square meter ownership. Each token corresponds to a specific geographic location and can be transferred or sold.</Text>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight="bold">Governance Token (Non-transferable ERC20):</Text>
                    <Text>
                      Automatically granted when you acquire your first land token. Gives you voting rights in the community 
                      through <Link href="https://snapshot.org" isExternal color="blue.500">Snapshot <ExternalLinkIcon mx="2px" /></Link> 
                      - a gasless governance platform used by major DAOs. If you sell all your land tokens, this governance token 
                      is automatically burned. Each member gets one vote regardless of how many square meters they own, ensuring 
                      fair and equal representation in community decisions.
                    </Text>
                  </ListItem>
                </List>
              </Box>

              {/* Example Flow */}
              <Box>
                <Heading size="md" mb={4}>Example Flow</Heading>
                <Code p={4} borderRadius="md" display="block" whiteSpace="pre" bg="gray.100">
{`// Alice buys 5 land tokens
landStewardship.mint(alice, tokenId, 5, "");
// Alice automatically gets 1 governance token

// Alice sells 3 land tokens to Bob
landStewardship.safeTransferFrom(alice, bob, tokenId, 3, "");
// Bob gets 1 governance token (first land tokens)
// Alice keeps her governance token (still has 2 land tokens)

// Alice sells her remaining 2 tokens to Carol
landStewardship.safeTransferFrom(alice, carol, tokenId, 2, "");
// Carol gets 1 governance token (first land tokens)
// Alice's governance token is burned (no more land tokens)`}
                </Code>
              </Box>

              {/* Smart Contracts */}
              <Box>
                <Heading size="md" mb={4}>Smart Contract Implementation</Heading>
                <Code p={4} borderRadius="md" display="block" whiteSpace="pre" bg="gray.100">
{`// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

import "@openzeppelin/contracts/token/ERC1155/ERC1155.sol";
import "@openzeppelin/contracts/token/ERC20/ERC20Votes.sol";

contract LandStewardship is ERC1155 {
    ERC20Votes public governanceToken;
    mapping(address => bool) public hasGovernanceRights;
    mapping(address => uint256) public totalLandTokens;
    
    function _beforeTokenTransfer(
        address operator,
        address from,
        address to,
        uint256[] memory ids,
        uint256[] memory amounts,
        bytes memory data
    ) internal virtual override {
        super._beforeTokenTransfer(operator, from, to, ids, amounts, data);
        if (from == address(0)) return;
        
        uint256 totalTransfer = 0;
        for (uint256 i = 0; i < amounts.length; i++) {
            totalTransfer += amounts[i];
        }
        
        totalLandTokens[from] -= totalTransfer;
        if (totalLandTokens[from] == 0 && hasGovernanceRights[from]) {
            _removeGovernanceRights(from);
        }
        
        totalLandTokens[to] += totalTransfer;
        if (!hasGovernanceRights[to] && totalLandTokens[to] > 0) {
            _grantGovernanceRights(to);
        }
    }
}

contract LandGovernanceToken is ERC20Votes {
    address public landStewardship;
    
    constructor() ERC20("Land Governance", "LAND-GOV") 
                ERC20Permit("Land Governance") {
        landStewardship = msg.sender;
    }
    
    function _beforeTokenTransfer(
        address from,
        address to,
        uint256 amount
    ) internal virtual override {
        require(
            from == address(0) || to == address(0),
            "Governance tokens are non-transferable"
        );
        super._beforeTokenTransfer(from, to, amount);
    }
}`}
                </Code>
              </Box>
            </VStack>
          </Box>

        </Container>
      </Box>
      <FooterWithFourColumns />
    </>
  );
};

export default PreviewOffer; 