// src/utils/helpers.ts

import axios from 'axios';

export const getApiUrl = () => {
  const hostname = window.location.hostname;
  
  if (hostname === "localhost") {
    return "http://localhost:4000/graphql";  // Local development GraphQL server URL
  } else {
    return "https://api.lifeessentials.club/graphql";  // Production GraphQL server URL
    // return "https://api.tommillerservices.com/graphql";  // Production GraphQL server URL
  }
};


export const getBitcoinPrice = async (): Promise<number> => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=aud');
    return response.data.bitcoin.aud;
  } catch (error) {
    console.error('Error fetching BTC price:', error);
    return 0; // Return 0 or some default value in case of an error
  }
};

export const calculateBTCAmount = (audAmount: number, btcPrice: number): number => {
  return audAmount / btcPrice;
};