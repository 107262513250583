import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Button,
  Progress,
  Stat,
  StatNumber,
  StatLabel,
  Divider,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useQuery } from '@apollo/client';
import { LoginModal } from '../components/LoginModal';
import { useAuth } from '../hooks/useAuth';

// Add this query
const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

interface Client {
  id: string;
}

interface GetClientsResponse {
  clients: Client[];
}

// Add interfaces for the component props
interface CategoryCardProps {
  title: string;
  description: string;
  items: string[];
}

interface BenefitItemProps {
  title: string;
  description: string;
}

const HomePage = () => {
  const navigate = useNavigate();
  const { data } = useQuery<GetClientsResponse>(GET_MEMBERS_COUNT);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated } = useAuth();

  // Calculate real numbers
  const TOTAL_LEGACY_MEMBERSHIPS = 100;
  const CURRENT_MEMBERS = data?.clients?.length || 0;
  const REMAINING_SPOTS = TOTAL_LEGACY_MEMBERSHIPS - CURRENT_MEMBERS;

  const handleSecurePosition = () => {
    if (isAuthenticated) {
      // Navigate to placeholder page - you can change this route later
      navigate('/onboarding');
    } else {
      onOpen(); // Open login modal if not authenticated
    }
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Box minH="100vh" bg={"#EFEDDC"}>
        <Image
          alt="Placeholder Image"
          src="/landing_page_image.webp"
          objectFit="cover"
          width="full"
        />
        <Container maxW="container.xl" py={20}>
          <VStack spacing={16}>
            {/* Hero Section */}
            <VStack spacing={6} textAlign="center">
              <Heading size="2xl">Byron Bay Token</Heading>
              <Text fontSize="xl" maxW="800px">
                Join an exclusive group of 100 founding members who will have the opportunity 
                to become stewards of premium land in Byron Bay through innovative blockchain technology.
              </Text>
            </VStack>

            {/* Early Access Status */}
            <Box w="full" maxW="800px" p={8} borderRadius="xl" bg="#404843">
              <VStack spacing={6}>
                <Heading size="md" color="white">Early Access Opportunity</Heading>
                <Text textAlign="center" color="white">
                  Be one of only {REMAINING_SPOTS} remaining early members to learn about exclusive 
                  land stewardship opportunities in Byron Bay.
                </Text>
                
                <Stat textAlign="center">
                  <StatLabel fontSize="lg" color="white">Available Early Access Positions</StatLabel>
                  <StatNumber fontSize="4xl" color="white">
                    {REMAINING_SPOTS} of {TOTAL_LEGACY_MEMBERSHIPS}
                  </StatNumber>
                </Stat>

                <Progress 
                  value={CURRENT_MEMBERS} 
                  max={TOTAL_LEGACY_MEMBERSHIPS} 
                  size="lg" 
                  colorScheme="blue" 
                  w="full"
                />
                
                <Text fontSize="sm" color="white">
                  {CURRENT_MEMBERS} members have already secured their position
                </Text>

                <Button 
                  size="lg" 
                  bg="#8b0100"
                  color="white"
                  _hover={{ bg: '#a30200' }}
                  onClick={handleSecurePosition}
                >
                  Secure Your Early Access Position
                </Button>
              </VStack>
            </Box>

            {/* Member Benefits */}
            <Box w="full" bg="gray.50" p={8} borderRadius="xl">
              <VStack spacing={6}>
                <Heading size="lg">Early Access Benefits</Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <BenefitItem 
                    title="Priority Property Access" 
                    description="First access to exclusive land stewardship opportunities in Byron Bay"
                  />
                  <BenefitItem 
                    title="Enhanced Transparency" 
                    description="Increased visibility and insight into the management of premium Byron Bay land"
                  />
                  <BenefitItem 
                    title="Digital Rights" 
                    description="Secure digital rights through blockchain technology"
                  />
                </SimpleGrid>
                <Button 
                  size="lg" 
                  colorScheme="blue" 
                  onClick={handleSecurePosition}
                >
                  <Text display={{ base: 'block', md: 'none' }}>Learn More</Text>
                  <Text display={{ base: 'none', md: 'block' }}>Learn More About Membership Opportunities</Text>
                </Button>
              </VStack>
            </Box>
          </VStack>
        </Container>
      </Box>
      <LoginModal isOpen={isOpen} onClose={onClose} />
      <FooterWithFourColumns />
    </>
  );
};

// Update the component definitions with types
const CategoryCard = ({ title, description, items }: CategoryCardProps) => (
  <Box p={6} borderRadius="lg" border="1px" borderColor="gray.200">
    <VStack align="start" spacing={4}>
      <Heading size="md">{title}</Heading>
      <Text>{description}</Text>
      <Divider />
      <VStack align="start" spacing={2}>
        {items.map((item: string) => (
          <Text key={item} fontSize="sm">• {item}</Text>
        ))}
      </VStack>
    </VStack>
  </Box>
);

const BenefitItem = ({ title, description }: BenefitItemProps) => (
  <Box p={4}>
    <Heading size="sm" mb={2}>{title}</Heading>
    <Text fontSize="sm" color="gray.600">{description}</Text>
  </Box>
);

export default HomePage;