import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Text,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

const REQUEST_AUTH = gql`
  mutation RequestAuth($input: AuthInput!) {
    requestAuth(input: $input)
  }
`;

const VERIFY_AUTH = gql`
  mutation VerifyAuth($input: VerifyInput!) {
    verifyAuth(input: $input)
  }
`;

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [verificationWords, setVerificationWords] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();

  const [requestAuth, { loading: requestLoading }] = useMutation(REQUEST_AUTH);
  const [verifyAuth, { loading: verifyLoading }] = useMutation(VERIFY_AUTH);

  const handleRequestAuth = async () => {
    try {
      const { data } = await requestAuth({
        variables: {
          input: {
            email,
            brand: 'ByronBayToken'
          }
        }
      });

      if (data.requestAuth) {
        setEmailSent(true);
        toast({
          title: "Verification email sent",
          description: "Please check your email for the verification words",
          status: "success",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to send verification email",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleVerifyAuth = async () => {
    try {
      const { data } = await verifyAuth({
        variables: {
          input: {
            email,
            words: verificationWords
          }
        }
      });

      if (data.verifyAuth) {
        localStorage.setItem('auth_token', data.verifyAuth);
        toast({
          title: "Success!",
          description: "You have been successfully logged in",
          status: "success",
          duration: 3000,
        });
        onClose();
        window.location.reload(); // Refresh to update auth state
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Verification failed",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleClose = () => {
    setEmail('');
    setVerificationWords('');
    setEmailSent(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            {!emailSent ? (
              <Box w="full">
                <FormControl>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </FormControl>
                <Button
                  mt={4}
                  colorScheme="blue"
                  onClick={handleRequestAuth}
                  isLoading={requestLoading}
                  w="full"
                >
                  Send Verification Email
                </Button>
              </Box>
            ) : (
              <Box w="full">
                <Text mb={4}>
                  Please enter the verification words sent to your email.
                </Text>
                <FormControl>
                  <FormLabel>Verification Words</FormLabel>
                  <Input
                    value={verificationWords}
                    onChange={(e) => setVerificationWords(e.target.value)}
                    placeholder="Enter verification words"
                  />
                </FormControl>
                <Button
                  mt={4}
                  colorScheme="blue"
                  onClick={handleVerifyAuth}
                  isLoading={verifyLoading}
                  w="full"
                >
                  Verify
                </Button>
              </Box>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}; 